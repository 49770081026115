import React from 'react'
import PrivateLayout from '../layouts/private'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import InstallPage from '../components/pages/InstallPage'

const InstallPageWrapper = (props) => {
  return <PrivateLayout>
    <InstallPage />
  </PrivateLayout>
}

InstallPageWrapper.propTypes = {

}

InstallPageWrapper.defaultProps = {

}

export default InstallPageWrapper
