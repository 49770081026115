import React from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import { Previous } from 'grommet-icons'
import { Link } from 'gatsby'

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin: auto auto;
  a {
    width: 250px;
    height: 70px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff22;
    padding: 12px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 6px;
    color: #fff;
  }
`

export const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  a {
    background-color: initial;
  }
`



const InstallPage = (props) => {
  return <Wrapper>
    <Back>
      <Link to='/'><Previous color='#fff' size='30px' /></Link>
    </Back>
    <a target="_blank" href="https://gist.github.com/BenAfonso/2ee87eb14c67c5a28de2dd4aa025a7df/raw/480ff821358a47b16d42ca050c8a52c73e65739c/lesamoureux-watchtogether.user.js">Watch Together</a>
    <a target="_blank" href="https://gist.github.com/BenAfonso/d0c6cdaa5b77491b925c7bc2fc86aeb7/raw/ff04c50af4a231dd859057e3c5ac9e8e0d418811/lesamoureux-tf1.user.js">TF1</a>
  </Wrapper>
}

InstallPage.propTypes = {

}

InstallPage.defaultProps = {

}

export default InstallPage
